import React from "react";
import Layout from "../components/layout";

export default function error(){
    return(
        <Layout>
            <div style={{ height: "40vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <h1 style={{textAlign:"center"}}>Page could not be found...</h1>
            </div>     
        </Layout>
    )
}